import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/util';
import 'slick-carousel';
import 'selectric';
import 'image-map';

$(window).on('load', function () {
	// -------------------------------------- Fixed menu when scrolling
	if (window.innerWidth > 991) {
		$(window).bind('scroll', function () {
			if ($(window).scrollTop() > 130) {
				$('.navbar').addClass('fixed');
			} else {
				$('.navbar').removeClass('fixed');
			}
		});
	}
	// ------------------------------------------- Partners map clicked
	$("#click_example").on("click", function() {
		$(".partners_list .image").removeClass("active");
		$(".partners_list .image").first().addClass("active");
		$(".partners_list .image").last().addClass("active");
	});
	// ----------------------------------------- Partners modal example
	$(".partners_list .image").on("click", function() {
		$('#partner_modal').modal('show');
	});
});

$(document).ready(function() {

	// ----------------------------------------------- Search in navbar
	$(".search").on("click", function () {
		$('.label').hide();
		$('.search_form').show();
		$('.search_form').find("input").focus();
		$('#search_overlay').show();
	});
	$("#search_overlay").on("click", function () {
		$('.label').show();
		$('.search_form').hide();
		$('#search_overlay').hide();
	});
	// -------------------------------------------------- Home Carousel
	$(".carousel--news").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true
	});

	// ------------------------------------------- Partners map clicked
	var $map = $('img[usemap]')
	$map.imageMap(500);
	$('area').on("click", function () {
		var title = $(this).attr("title");
		// chaneg map src
		var selector = 'img[usemap=\'#' + $(this)[0].parentElement.name + '\']';
		document.querySelector(selector).setAttribute('src', '/assets/images/europe_' + title + '.png');
		// highligth partners
		$(".partners_list .image").removeClass("active");
		$(".partners_list .image." + title).addClass("active");
	});
	// ----------------------------------------- Partners modal example
	$(".partners_list .image").on("click", function () {
		// change data
		$('#partner_modal .title').html($(this).data("title"));
		$('#partner_modal .location').html($(this).data("location"));
		$('#partner_modal .text').html($(this).data("description"));
		$('#partner_modal #modal_image').attr("src", $(this).children().attr("src"));
		$('#partner_modal #key-contact').text($(this).data("email"));
		var $link_container = $('#partner_modal #link_container');
		$link_container.html("");
		var n_link = $(this).data("link-size");
		for (var i = 0; i < n_link; i++) {
			var name = i === 0 ? 'Website' : 'Twitter';
			$link_container.append('<a href="' + $(this).data("link-" + i) + '" target="_blank" class="c-btn c-btn--b mb-3">' + name + '</a>');
		}
		//show modal
		$('#partner_modal').modal('show');
	});

	$('select').selectric();

	// ----------------------------------------- SUBSCRIPTION

	var $formSubscribe = $('#formSubscribe');
	var $sign_up_button = $('#sign_up_button');

	$sign_up_button.on("click", function () {
		clearFormSubscribe();
		$loadingSubscribe.css("display", "none");
		$successfullSubscribe.css("display", "none");
		$errorSubscribe.html("");
		$errorSubscribe.hide();
	});

	function clearFormSubscribe() {
		$formSubscribe.trigger('reset');
		$formSubscribe.removeClass('was-validated');
	}

	$formSubscribe.submit(function (ev) {
		ev.preventDefault();

		var data = {};
		$.each($formSubscribe.serializeArray(), function (i, field) {
			data[field.name] = field.value;
		});
		//serialized checkbox
		var serializedCheckbox = $('input:checkbox', $formSubscribe).map(function () {
			return {name: this.name, value: this.checked ? "true" : "false"};
		});
		for (var i = 0; i < serializedCheckbox.length; i++) {
			data[serializedCheckbox[i].name] = serializedCheckbox[i].value;
		}

		_iub.cons_instructions.push(['submit', {
			form: {
				selector: $formSubscribe[0],
			},
			consent: {
				legal_notices: [
					{
						identifier: 'privacy_policy'
					}
				]
			}
		}]);

		subscribe(data);

	});

	var $errorSubscribe = $('#errorSubscribe');
	var $successfullSubscribe = $('#successSubscribe');
	var $loadingSubscribe = $('#loadingSubscribe');
	$loadingSubscribe.css("display", "none");

	function subscribe(data) {

		var jdata = JSON.stringify(data);

		$.ajax({
			type: "POST",
			url: "/subscribe",
			data: jdata,
			contentType: 'application/json',
			beforeSend: function () {
				$errorSubscribe.hide();
				$errorSubscribe.html("");
				$successfullSubscribe.hide();
				$loadingSubscribe.css("display", "block");
			},
			complete: function () {
				$loadingSubscribe.css("display", "none");
			},
			success: function (response) {
				_iub.cons.sendData();
				setTimeout(function () {
					// var message = data.Message;
					$errorSubscribe.hide();
					$errorSubscribe.html("");
					$successfullSubscribe.show();
					clearFormSubscribe();
					setTimeout(function () {
						$('#newsletter_modal').modal('toggle');
					}, 1500);
				}, 0);
			},
			error: function (request, status, error) {
				$errorSubscribe.show();
				handleError($errorSubscribe, request, status, error);
			}
		});
	}

	function handleError($erroElement, request, status, error) {
		console.error(request.responseText);
		if (!request.responseText) {
			$erroElement.html("Error - message not sent:<br/>" + error);
			return;
		}
		try {
			var data = JSON.parse(request.responseText);
			if (data && data.Message) {
				$erroElement.html(data.Message + ".<br/>Correct the fields and resubmit the form.");
			} else {
				$erroElement.html(error);
			}
		} catch (e) {
			$erroElement.html("Error - message not sent:<br/>" + error);
		}
	}

	// Fetch all the forms we want to apply custom Bootstrap validation styles to
	var forms = document.getElementsByClassName('needs-validation');
	// Loop over them and prevent submission
	var validation = Array.prototype.filter.call(forms, function (form) {
		form.addEventListener('submit', function (event) {
			if (form.checkValidity() === false) {
				event.preventDefault();
				event.stopPropagation();
			}
			form.classList.add('was-validated');
		}, false);
	});

	// analytics events
	var $toSend = $('.send-event');
	$toSend.on('click', function() {
		var label = $(this).text();
		var type = $(this).data('type');
		gtag('event', 'Download', {
			'event_category': type,
			'event_label': label
		})
	});
});
